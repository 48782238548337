import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Map from '../components/Map';

import Image from '../components/images/AboutUsImage';

import styles from './about.module.scss';

const AboutPage = props => (
  <Layout location={props.location} className={styles.root}>
    <SEO title="關於我們" />
    <section className={styles.lead}>
      <div className={styles.wrapper}>
        <h1>新一代特色迷你倉</h1>
        <p>
          Roomyr管理層於本港營運出租迷你倉有10多年經驗，考慮香港住屋環境愈趨狹窄，迷你倉已成為普羅大眾的生活申延，除提供優良安全合法儲物倉庫外，Roomyr更著重提供”貼地”產品，本公司三大特色自存倉：衣櫃倉、書櫃倉及精品倉，絕非純粹噱頭產品，倉內預設收藏工具及置小活動櫃為精心設計，令你將家居物品整齊地安放於租倉之外，更可以不受囤積物品的影響下輕鬆地提取你物品，真正地使用儲存倉庫內每個空間。
        </p>
        <p>重建優化品牌目的為可以提供更好服務及質素予客戶，生活不是只提供一個空白儲存倉庫，我們令申延變得更真實。</p>
      </div>
    </section>
    <section className={styles.contact}>
      <div className={styles.image}>
        <Image />
      </div>

      <header>聯絡我們</header>

      <div className={styles.info}>
        <div className={styles.icon}>
          <FontAwesomeIcon fixedWidth icon={['fal', 'map-marker-alt']} />
        </div>
        <div>九龍紅磡馬頭圍道21號義達工業大廈12樓B1室</div>
      </div>

      <div className={styles.info}>
        <div className={styles.icon}>
          <FontAwesomeIcon fixedWidth icon={['fal', 'phone']} />
        </div>
        <div>2111 3113</div>
      </div>

      <div className={styles.info}>
        <div className={styles.icon}>
          <FontAwesomeIcon fixedWidth icon={['fal', 'clock']} />
        </div>
        <div>星期一至日 10:00am - 07:00pm</div>
      </div>

      <div className={styles.map}>
        <Map
          zoom={17}
          coords={{
            lng: 114.187867,
            lat: 22.308493
          }}
        />
      </div>
    </section>
  </Layout>
);

export default AboutPage;
